@import "../../variables";

.side {


  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    grid-gap: 2px;
    height: $order-book-row-height;
    align-items: center;

    transition: all .3s ease-in-out;

    &>div {
      padding: 0 5px;
      text-align: right;
    }

    &.row-active.increasing {
      background-color: var(--background-color10);
    }
    &.row-active.decreasing {
      background-color: var(--background-color11);
    }
    &.row-active.stable {
      background-color: var(--background-color8);
    }

    div.amount {
      min-width: 25%;
      text-align: right;
    }
    div.total {
      width: 65px;
      min-width: 65px;
      max-width: 65px;
    }
    div.count {
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      text-align: center;
      white-space: nowrap;
    }
    div.price {
      min-width: 25%;
    }

    div.placeholder {
      width: 15px;
    }
    div.alert {
      width: 15px;
    }

  }



}

.side.side-bids {
  direction: rtl;

  .row {
    &>.price {
      text-align: left;
    }
  }
}

@media (max-width: $breakpoint-order-book) {

  .side.side-bids {

    .row {
      &>.price {
        text-align: right;
      }
    }
  }
}
