@import '../../variables';

.side {
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow: hidden;


  &.disconnected {
    opacity: $disabled-opacity;
  }

  .rows-container {
    z-index: 2;
  }
}

@media (max-width: $breakpoint-order-book) {

  .order-book {
    .book-view {
      .side {
        width: 100%;
        height: $order-book-row-height * 10;
      }

      .rows-container {
        height: $order-book-row-height * 10;
        overflow: hidden;
      }

      .side-asks {
        padding-top: $order-book-row-height;

        .header-rows {
          margin-top: $order-book-row-height * -1;
        }

        .rows {
          display: flex;
          flex-direction: column-reverse;
          margin-top: $order-book-row-height * -15;
        }

      }

      .side-bids {
        direction: ltr;

        .header {
          height: 0px;
          border-bottom: 1px solid var(--border-color6);
        }

      }
    }
  }
}
