@import "../../variables";

.side {
  .bars {
    height: 0;
    z-index: 1;

    .svg-container {
      overflow: hidden;
    }

    svg {
      margin-top: $order-book-row-height;
    }

    rect {
        transition: all .3s ease-in-out;
    }
  }

  &.side-asks {
    svg {
      transform: scale(-1, 1);
    }
    .bars {
      rect {
        fill: var(--background-color10-light);
      }
    }
  }
  &.side-bids {
    svg {
      transform: scale(1, -1);
    }
    .bars {
      rect {
        fill: var(--background-color11-light);
      }
    }
  }

}

@media (max-width: $breakpoint-order-book) {

  .order-book {
    .book-view {

      .side-asks {
        svg {
          margin-top: $order-book-row-height * -15;
          transform: scale(-1, -1);
        }
      }

      .side-bids {
        svg {
          margin-top: $order-book-row-height * -3;
          transform: scale(-1, -1);
        }
      }

    }
  }
}
