

.order-book {
  .toolbar {

    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
    margin: 0;
    height: 32px;
    line-height: 28px;
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    user-select: none;
    justify-content: space-between;
    align-items: center;
    font-size: .9rem;
    padding: 0 0 0 5px;

    .detail {
      opacity: 0.7;
    }
    button {
      border: 0;
      color: var(--text-color1);
    }

  }
}
