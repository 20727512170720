
.side {
  .header {

      display: flex;
      flex-direction: row;
      grid-gap: 2px;
      text-transform: uppercase;
      opacity: 0.6;



  }



}
