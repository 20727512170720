
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import 'variables';



body {
  --background-color10: rgba(1, 167, 129, 0.5);
  --background-color10-light: #b8dbd2;
  --background-color11: rgba(228,75,68, 0.5);
  --background-color11-light: #eac5c5;
}

@mixin dark-mode {
  --background-color1: #0b1923;
  --background-color8: #01a781;
  --background-color4: #172d3e;
  --background-color5: #0e3452;
  --background-color3: #102331;

  --border-color2: #172d3e;
  --border-color6: #485054;

}

@mixin light-mode {

  --background-color1: #f1f1f1;
  --background-color4: #e4e4e4;
  --background-color3: #ebebeb;
  --background-color5: #dad8d8;
  --background-color8: #03ac84;
  --border-color2: #bfc3c6;
  --border-color6: #485054;

  --text-color1: #040c0f;

}

.dark-mode {
  @include dark-mode;
}
.light-mode {
  @include light-mode;
}

body {
  background-color: var(--background-color3);
  color: var(--text-color1);
  font-family: Roboto, sans-serif;

}

@media (prefers-color-scheme: light){
    body {
        @include light-mode;
    }
}
@media (prefers-color-scheme: dark){
  body {
    @include dark-mode;
  }
}
