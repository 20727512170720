@import '../variables';


.order-book {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 11px;
  position: relative;
  max-width: 1200px;

  background: var(--surface-color);
  .book-view {
    width: 100%;
    display: flex;
    flex-direction: row;

  }
}


@media (max-width: $breakpoint-order-book) {
  .order-book {
    .book-view {
      flex-direction: column;

    }
  }

}
