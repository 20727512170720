
.status-overlay {

  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;


  div {
    background-color: var(--background-color4);
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: 2px solid var(--border-color6);
    box-shadow: 2px 2px 0px var(--border-color2);
  }
  div:hover {
    background-color: var(--background-color5);
  }
}
