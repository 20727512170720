body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  border: 0;
  background: transparent;
  font-family: inherit;
}
